<template>
    <v-card 
        flat
    >
        <v-card-title class="pl-0">Student Subject Assignment</v-card-title>
        
        <v-row align="center" justify="center">
            <v-col cols="5">                                                                
                <v-autocomplete
                    v-model="selectedSubject"
                    label="Select Subject"
                    hide-details
                    outlined
                    dense
                    :items="subjects"
                    item-text="title"
                    item-value="id"
                    chips
                    small-chips
                    @change="getStudentsAssigned"
                    height="20"
                    return-object
                >
                    <template v-slot:selection="data">
                        <v-chip
                            v-bind="data.attrs"
                            :input-value="data.selected"
                            close
                            @click:close="clearSubject"
                            small
                        >
                            <!-- {{ data.item.title }} -->
                            {{ formattedSubjectTitle }}
                        </v-chip>
                    </template>
                </v-autocomplete>                
            </v-col>

            <v-col cols="2">
                <v-select
                    v-model="selectedFormLevel"
                    label="Form"
                    hide-details
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    :items="formLevels"
                    @change="setStudentsList"
                ></v-select>
            </v-col>

            <v-col cols="3">                  
                <v-select
                    v-model="selectedFormClass"
                    label="Select Class"
                    hide-details
                    outlined
                    dense
                    chips
                    small-chips
                    deletable-chips
                    :items="formClassOptions"
                    item-text="id"
                    item-value="id"
                    @change="setStudentsList"                  
                ></v-select>
            </v-col>

            <v-col cols="2">
                <v-select
                    v-model="displayStudents"                    
                    outlined                    
                    label="Display"                    
                    :items="displayOptions"                                                       
                    hide-details
                    dense
                    @change="filterDisplay"
                ></v-select>
            </v-col>            
        </v-row>
            
        <v-row>
            <v-col cols="6" class="pt-0">                   
                <v-autocomplete
                    v-model="selectedTeacher"
                    label="Select Teacher"
                    hide-details
                    outlined
                    dense
                    :items="teachers"
                    item-text="name"
                    item-value="id"
                    chips
                    small-chips
                    deletable-chips                    
                ></v-autocomplete>
            </v-col>

            <v-col cols="2" class="pt-0">
                <v-text-field
                    outlined
                    disabled
                    label="Assigned"
                    v-model="studentsAssignedCount"
                    hide-details
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="4" class="pt-0">
                <v-text-field
                    v-model="search"
                    label="Search Student"
                    append-icon="mdi-magnify"
                    outlined
                    clearable
                    hide-details
                    dense
                ></v-text-field>
            </v-col>

            
        </v-row>

        <v-data-table
            v-model="selected"
            :headers="headers"
            :items="studentsList"
            fixed-header                        
            height="48vh"         
            :search="search"
            :loading="loading"
            show-select
            item-key="student_id"
            dense                
        >
            <template v-slot:[`item.assigned`]="{ item }">
                <v-icon
                    v-if="item.assigned"
                    color="primary"
                >
                    mdi-checkbox-marked-outline
                </v-icon>
            </template>            
        </v-data-table>
        
        <v-card-actions>
            <v-spacer></v-spacer>
            <!-- <v-btn
                depressed
                @click="assignStudents"
                class="mr-6"
            >
                Download
            </v-btn> -->
            <v-btn
                depressed
                @click="unassignStudents"
                class="mr-4"
            >
                unassign Students
            </v-btn>
            <v-btn
                color="primary"
                @click="assignStudents"
            >
                Assign Students
            </v-btn>
        </v-card-actions>

        <v-snackbar
            v-model="snackbar.display"
            :color="snackbar.color"
            :centered="snackbar.centered"            
        >
            {{ snackbar.text }}
            <template v-slot:action="{ attrs }">
                <v-btn
                    dark
                    text
                    v-bind="attrs"
                    @click="snackbar.display = false"                   
                >
                    Close
                </v-btn>
            </template>
        </v-snackbar>

        <v-overlay :value="overlay">
            <v-progress-circular 
                indeterminate 
                size="64"
            ></v-progress-circular>
        </v-overlay>
                
    </v-card>      
</template>

<script>
import { mapActions, mapMutations } from 'vuex';
export default {
    props: {
        subjects: {
            type: Array,
            default: function () {
                return [];
            }
        },

        teachers: {
            type: Array,
            default: function () {
                return [];
            }
        },

        formLevels: {
            type: Array,
            default: function () {
                return [];
            }
        },

        formClasses: {
            type: Array,
            default: function () {
                return [];
            }
        },       

        students: {
            type: Array,
            default: function () {
                return [];
            }
        },
    },

    mounted: function () {
        this.initialize();
    },

    computed: {
        formClassOptions () {            
            if(this.selectedFormLevel){               
                return this.formClasses.filter(value => {
                    if(value.form_level == this.selectedFormLevel ) 
                    return value;
                })
            } 
            return this.formClasses;           
        },

        studentsAssignedCount () {
            if(this.subjectStudentsAssigned.length == 0) return '-';
            return this.subjectStudentsAssigned.length;
        },

        formattedSubjectTitle () {
            if(this.selectedSubject && this.selectedSubject.title.length > 25){
                return this.selectedSubject.abbr;
            }
            return this.selectedSubject.title;
        }
        
    },

    data: () => ({
        selectedSubject: null,
        selectedTeacher: null,
        selectedFormLevel: null,
        selectedFormClass: null,
        headers: [
            {text: 'ID', align: 'start', value: 'student_id', width: '100'},
            {text: 'Name',  value: 'name', width: '200'},            
            {text: 'Class', value: 'form_class_id', width: '100'},            
            {text: 'Teacher',  value: 'teacher', width: '150' },
            {text: 'Assigned',  value: 'assigned', align: 'center' },
        ],       
        search: '',
        loading: false,
        selected: [],
        studentsList: [],
        studentsAssigned: [],
        subjectStudentsAssigned: [],
        displayOptions: [ "All", "Assigned", "Unassigned"],                
        displayStudents: "",
        overlay: false,
        snackbar: {
            display: false,
            text: '',
            centered: false,
            color: 'primary',
        },
    }),

    watch: {
        selectedFormLevel: {
            handler () {
                // console.log("form level changed");
                this.selectedFormClass = null;
                this.displayStudents = "All";
                this.setStudentsList();
            }
        },

        
        selectedFormClass: {
            handler () {
                // console.log(`Form Class: ${value}`);
                this.displayStudents = "All";
            }
        }
    },

    methods: {
        ...mapActions({
            getSubjectStudentsAssigned: 'subjectAssignments/getStudentsAssigned',
            postSubjectStudentsAssignment: 'subjectAssignments/postSubjectStudentsAssignmentBatch',
            deleteSubjectStudentAssignments: 'subjectAssignments/deleteSubjectStudentAssignments',
        }),

        ...mapMutations({
            setSubjectSelected: 'subjectAssignments/setSubjectSelected',
            setSubjectStudentsAssignment: 'subjectAssignments/setSubjectStudentsAssignment',
        }),
        
        initialize () {
            this.displayStudents = "All";            
            this.selected = [];
            this.studentsList = [];
            this.studentsAssigned = [];
            this.subjectStudentsAssigned = [];
            this.$emit('show-overlay', false)
        },
        
        async getStudentsAssigned () {
            if(this.selectedSubject){
                this.setSubjectSelected(this.selectedSubject.id);
                try {
                    const { data } = await this.getSubjectStudentsAssigned();
                    this.studentsAssigned = data;
                } catch (error) {
                    if(error.response) console.log(error.response);
                    else console.log(error);
                }
            }
            this.setStudentsList();
        },

        setStudentsList () { 
            this.studentsList = [];   
            if(this.selectedSubject.id && !this.selectedFormLevel && !this.selectedFormClass){
                console.log("subject only selected")
                this.studentsList = [];
                return
            } 

            if(this.selectedSubject.id && this.selectedFormLevel && !this.selectedFormClass)
            {
                console.log("only form level selected");
                this.students.forEach(value => {
                    if(value.form_class_id && value.form_class_id[0] == this.selectedFormLevel){
                        value.name = value.last_name + ', ' + value.first_name
                        this.studentsList.push({...value});
                    }
                })

                this.setStudentsAssigned();
            }
            else if(this.selectedSubject.id && this.selectedFormClass)
            {                
                console.log("subject and form class selected")
                this.students.forEach(value => {
                    if(value.form_class_id == this.selectedFormClass)
                    this.studentsList.push({...value});
                })
                this.setStudentsAssigned();
            }
                     
        },

        setStudentsAssigned () {
            let student = null, teacher = null;
            this.selected = [];
            this.subjectStudentsAssigned = [];
            this.studentsList = this.studentsList.map(value => {
                student = this.studentsAssigned.filter(item => {
                    if(item.student_id == value.student_id) return item;
                })
                value.assigned = false;
                value.teacher = null;
                if(student.length > 0){
                    teacher = null;
                    if(student[0].employee_id){
                        // teacher = this.teachers.filter(teacher => {
                        //     if(teacher.id == student[0].employee_id)
                        //     return teacher;
                        // })[0].teacher_name;
                        teacher = student[0].teacher_name;
                        
                    }
                    value.teacher = teacher;
                    value.assigned = true;
                    this.subjectStudentsAssigned.push(value);
                    // this.selected.push(value);
                }
                return value;
            })
        },

        async assignStudents () {
            if(this.selected.length === 0){
                this.snackbar.color = "red";
                this.snackbar.text = "No students selected for assignment";
                this.snackbar.display = true;
                this.snackbar.centered = true;
                return;
            }

            this.setSubjectStudentsAssignment({
                form_level: this.selectedFormLevel,
                form_class_id: this.selectedFormClass,
                students: this.selected,
                employee_id: this.selectedTeacher,
                subject_id: this.selectedSubject.id
            })

            await this.postAssignment();

        },

        async postAssignment () {
            this.$emit('show-overlay', true)
            try {
                await this.postSubjectStudentsAssignment();
                this.displayStudents = "All";
                this.getStudentsAssigned();
                // this.setStudentsList();
                this.$emit('show-overlay', false);
                this.$nextTick(() => {
                    let msg = `${ this.selected.length} Student Assigned`;
                    if(this.selected.length > 1)
                    msg = `${ this.selected.length} Students Assigned`;
                    this.snackbar.text = msg;
                    this.snackbar.color = "primary";
                    this.snackbar.centered = false; 
                })
            } catch (error) {
                if(error.response) console.log(error.response);
                else console.log(error);
                this.snackbar.text = "An error has occured.";
                this.snackbar.color = "red";
                this.snackbar.centered = false; 
            }
            this.snackbar.display = true;
        },

        filterDisplay () {            
            let studentAssigned = [];
            switch (this.displayStudents){
                case "All":
                    this.setStudentsList();
                    break;
                case "Assigned":
                    this.setStudentsList();                    
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.student_id == value.student_id) return item;
                        })
                        if(studentAssigned.length > 0){
                            return value;
                        }
                    })
                    break;
                case "Unassigned":
                    this.setStudentsList();
                    this.studentsList = this.studentsList.filter(value => {
                        studentAssigned = this.studentsAssigned.filter(item => {
                            if(item.student_id == value.student_id) return item;
                        })
                        if(studentAssigned.length == 0){
                            return value;
                        }
                    })
                    break;        
           }
        },

        clearSubject () {
            this.selectedSubject = null;
            this.initialize();
        },

        async unassignStudents () {
            if(this.selected.length === 0){
                this.snackbar.color = "red";
                this.snackbar.text = "No students selected for unassignment";
                this.snackbar.display = true;
                this.snackbar.centered = true;
                return;
            }

            this.$emit('show-overlay', true)

            this.setSubjectStudentsAssignment({
                students: this.selected,
                employee_id: this.selectedTeacher,
                subject_id: this.selectedSubject.id
            })

            try {
                const { data } = await this.deleteSubjectStudentAssignments();
                this.displayStudents = "All";
                this.getStudentsAssigned();
                this.$nextTick(() => {
                    let msg = `${ data } Student unassigned`;
                    if(data  > 1)
                    msg = `${ data } Students unassigned`;
                    this.snackbar.text = msg;
                    this.snackbar.color = "primary";
                    this.snackbar.centered = false;
                    this.snackbar.display = true;
                })
            } catch (error) {
                error.response ? console.log(error.response) : console.log(error)
                this.snackbar.text = "An error has occured. Could not unassign students";
                this.snackbar.color = "red";
                this.snackbar.centered = false;
                this.snackbar.display = true; 
            }

            this.$emit('show-overlay', false);
        },

        
    }
}
</script>

<style scoped>
    ::v-deep .v-input{
        font-size: 12px;
    }

    ::v-deep .v-text-field input{
        text-align: center;
    }

    ::v-deep .v-select__selections{
        flex-wrap: nowrap;
    }

    ::v-deep .v-data-footer {
        justify-content: flex-start;
    }

    ::v-deep .v-card__actions {
        position: absolute;
        right: 0;
        bottom: 0;
    }

    ::v-deep .v-select.v-select--chips:not(.v-text-field--single-line).v-text-field--enclosed.v-select--chips--small.v-input--dense .v-select__selections {
        min-height: 20px;
    }

    ::v-deep table th:first-child{
        width: 40px !important;
    }

    ::v-deep th span{
        display: inline;
    }
</style>